.radio-wrapper {
  .input-label {
    display: block;
    position: relative;
    color: $color-grey-dark;
    margin-bottom: 16px;
    padding-left: 40px;
    font-size: 16px;
    line-height: 1.5;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border: 1px solid $color-grey-light;
      border-radius: 24px;
      line-height: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      background-color: $color-orange-medium;
      border-radius: 8px;
    }
  }

  input[type="radio"]:checked + label {
    &:after {
      transform: scale(1);
    }
  }

  input[type="radio"]:hover + label:before,
  input[type="radio"]:focus + label:before {
    border-color: $color-orange-medium;
    box-shadow: rgba(240, 128, 0, 0.14) 0px 5px 20px !important;
  }

  &.error {
    .input-label:before {
      border-color: $color-error-red;
    }

    .validation-message {
      margin-top: 8px;
    }
  }
}
