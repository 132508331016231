.form-custom-wrapper {
  padding: 24px 16px;
  color: #414141;
  background: #F2F2F2;
  border: 1px solid rgb(204, 204, 204);

  @media (min-width: 1024px) {
    padding: 32px 80px;
  }

  .text-orange {
    color: #ea650d;
  }

  b {
    font-weight: 500;
    font-family: NNNittiGrotesk-Heading;
  }

  .nn-validation-message {
    font-size: 12px;
    line-height: 1.2;
  }

  .form-desc {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;

    a {
      font-size: inherit;
      color: #ea650d;
    }
  }

  .form {

    .form-header {
      margin-bottom: 30px;
      color: #ea650d;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.25;
    }

    fieldset {
      margin-bottom: 15px;
    }

    .control-group {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 5px;
      }

      .controls {

        input {
          font-size: 16px;
        }
      }
    }

    .label {
      color: #414141;
      font-size: 16px;
      line-height: 1.2;

      a {
        font-size: 16px;
      }
    }

    .legal {
      margin-top: 5px;
      margin-bottom: 0;
      color: #8b8b8b;
      font-size: 12px;
      line-height: 1.2;

      img {
        display: inline-block;
        margin-top: -3px;
      }
    }

    .link {
      color: #414141;
      font-size: 12px;
      text-decoration: underline;
    }

    .agreement {
      color: #414141;
      font-size: 12px;
      line-height: 1.2;
    }

    .input-wrapper.checkbox {
      margin-bottom: 15px;
    }

    .btn {
      background-color: #EA650D;
      border-color: #EA650D;

      &:hover {
        background-color: #e64415;
        border-color: #e64415;
      }
    }

    .btn-submit {
      width: 100%;
    }

    &.loading {

      &:before {
        background: hsla(0, 0%, 95%, .75) !important;
      }
    }
  }

  .form-thx {
    display: none;
    padding: 150px 0 150px;
    color: #414141;
    background: #F2F2F2;
    font-size: 1.31rem;

    .ico-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;

      div {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 50%;
        background-color: #347663;
      }
    }

    .thx-header {
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 8px;
    }

    .thx-desc {
      font-size: 20px;
      line-height: 1.4;

      &:nth-of-type(2) {
        margin-bottom: 32px;
      }
    }

    ul {
      padding-left: 10px;
    }
  }

  .custom-file-input {

    .file-input-wrapper {
      width: 100%;
      height: 290px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      background-color: #fff;
      box-shadow: 0px 1px 4px rgba(65, 65, 65, 0.1), 0px 3px 10px rgba(65, 65, 65, 0.1), 0px 10px 20px rgba(65, 65, 65, 0.1);
      border-radius: 3px;

      &:before {
        position: absolute;
        content: "";
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        top: 16px;
        left: 16px;
        border: 2px dashed #BDBDBD;
        border-radius: 3px;
      }

      .preview {
        display: none;
        position: absolute;
        content: "";
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        top: 16px;
        left: 16px;
        background-color: #fff;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
      }

      .camera-photo {
        width: 100px;
        height: 70px;
        position: relative;
        margin-bottom: 40px;

        .icon {
          width: 48px;
          height: 48px;
          position: absolute;
          right: -24px;
          bottom: -24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #EA650D;
          border-radius: 50%;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }

      legend {
        margin-bottom: 0;
        color: #414141;
        font-size: 16px;
        font-family: NNNittiGrotesk-TextRegular;
      }

      label {
        width: 140px;
        margin: 16px auto 40px;
        font-size: 16px;
      }

      input {
        display: none;
      }
    }

    .reset-custom-file {
      display: none;
      margin-top: 24px;
      text-align: right;

      button {
        color: #EA650D;
        border-bottom: 2px solid #EA650D;
        border-radius: 0;
        font-family: NNNittiGrotesk-Heading;
        font-size: 16px;
      }
    }

    &.error {

      .file-input-wrapper {

        &:before {
          border: 2px solid #EA650D;
        }
      }
    }

    &.loaded {

      .preview {
        display: block;
      }

      .reset-custom-file {
        display: block;
      }
    }
  }
}

// global styles for forms (copied for edition)
.form-custom-wrapper {

  .circle {
    display: inline-block;
    margin-right: 10px;
    line-height: 43px;
    color: #666;
    border-radius: 13px
  }

  .circle:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: #ee7101 url(assets/icons-white.4f01b1b29e8c4edcd4043cad0bff1f7f.png) repeat-y 0 0
  }

  .circle:hover {
    color: #ee7101
  }

  .circle.small {
    line-height: 26px
  }

  .circle.small:before {
    width: 26px;
    height: 26px;
    margin-top: -1px;
    margin-right: 7px;
    border-radius: 13px;
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png)
  }

  .circle.small.right:before {
    background-image: url(assets/icons-small-white-horizontal.0ec1e6aa87ecbb4c51fb005c2ef9534d.png);
    background-position: 0 -50px;
    background-repeat: repeat-x
  }

  .circle.small.cross:before {
    background-image: url(assets/icons-small-white.20cf0a03e96a15260dbf4f83ac985978.png);
    background-position: -350px -51px
  }

  .conditional-section {
    position: relative
  }

  .conditional-section [data-show] {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease 0ms, visibility 200ms ease 0ms;
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
  }

  .conditional-section [data-show].active {
    position: static;
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: all 200ms ease 200ms, visibility 200ms ease 200ms
  }

  .conditional-section .nice-select .option.disabled {
    display: none !important;
  }


  .file-upload-button {
    background: none;
    border: none;
    outline: 0;
    padding: 0
  }

  .file-upload-remove,
  .file-upload-filename,
  .filled .file-upload-button {
    display: none
  }

  .filled .file-upload-remove,
  .filled .file-upload-filename {
    display: block
  }

  .file-upload-remove {
    float: right
  }

  .file-upload-filename {
    min-height: 2.125rem;
    padding: .375rem 0 .5rem 2.25rem;
    line-height: 1.625rem;
    background: url(assets/ico-doc.92b902376853794d3aa60fc34870d1ae.png) no-repeat 0 5px !important
  }

  .file-upload-filename span {
    word-break: break-all;
    word-break: break-word
  }

  .file-upload-filename em {
    font-style: normal;
    color: #999
  }

  .error .file-upload-wrapper .nn-validation-message {
    color: #e64415;
  }

  .filled+.file-upload-wrapper {
    margin-top: 1.75rem
  }

  .filled+.filled {
    margin-top: 0
  }

  .global-error .error {
    display: block;
    padding: .25rem 0 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #e64415
  }

  .global-error .error .big {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 979px) {
    .complaint-form .form-horizontal .narrow {
      padding-right: 0
    }

    .complaint-form .form-horizontal .control-group {
      width: 100%
    }

    .complaint-form .form-horizontal .controls {
      margin-left: 0
    }

    .complaint-form .form-horizontal .control-label.radio-label {
      width: 100% !important
    }
  }

}