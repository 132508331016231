.blog-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .params {
    font-size: 16px;

    @media (max-width: 380px) {
      font-size: 14px;
    }
  }

  .btn {
    position: relative;
    padding: 13px 24px;
    font-size: 18px;
    color: #414141;
    line-height: 1.22;

    &.hollow {
      padding: 13px 5px;
      border: 1px solid transparent;
      background: transparent;
      font-size: 16px;

      @media (max-width: 380px) {
        font-size: 14px;
      }

      .chevron {
        position: relative;
        padding-left: 25px;

        &:before {
          content: "";
          width: 7px;
          height: 14px;
          position: absolute;
          top: 4px;
          left: 0;
          background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
          background-size: cover;
          transition: .2s;
        }
      }

      &:hover {
        color: #EA650D;

        .chevron {

          &:before {
            transform: translateX(4px);
          }
        }
      }
    }

    &.prev {

      .chevron:before {
        left: unset;
        right: 0;
        transform: rotate(180deg);
        //background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-left.svg");
      }

      &:hover {

        .chevron {

          &:before {
            transform: translateX(-4px) rotate(180deg);
          }
        }
      }
    }
  }
}
