.section-nnlife-form {
  padding: 32px 0;

  @media screen and (min-width: 40em) {
    .input-row {
      display: flex;
      justify-content: space-between;

      &>div {
        flex: 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
      }
    }
  }

  &>.row {
    max-width: 60rem;
  }

  .input-row-inline {
    .input-wrapper {
      display: inline-block;
      margin-right: 20px;

      @media screen and (max-width: 39.99875em) {
        margin-bottom: 0;
      }
    }
  }

  p.small {
    font-size: 12px;
    margin: 16px 0;

    a {
      font-size: inherit;
    }
  }

  .control-group {
    margin-bottom: 30px;

    .file-upload-wrapper {
      min-height: 30px;
    }
  }

  .submit-button-wrapper {
    text-align: center;
    margin-top: 20px;
  }

  .section-title {
    font-size: 28px;
    line-height: 1.3;
    font-family: NNNittiGrotesk-Heading;
    margin-bottom: 32px;
    text-align: center;

    .section-title-subtitle {
      display: block;
      font-size: 16px;
      font-family: NNNittiGrotesk-TextRegular;
      margin-top: 8px;
    }
  }

  .form-thx {
    display: none;
  }
}