.section-superbrands-tabs {
  padding: 16px 0;

  @media screen and (min-width: 40em) {
    padding: 48px 0;
  }

  .no-padding {
    padding: 0;
  }

  h2,
  h3 {
    font-size: 28px;
    line-height: 36px;
    font-family: NNNittiGrotesk-Heading;
    margin-bottom: 16px;
  }

  .section-header-small {
    margin-bottom: 8px;
  }

  .section-title {
    @media screen and (max-width: 39.99875em) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  .content-wrapper {
    background: #F7F7F7;
    padding: 24px 32px 8px;

    @media screen and (min-width: 40em) {
      padding: 40px 48px 24px;
    }
  }

  .content-container {
    background-color: #F7F7F7;
  }

  .tabs {
    .triggers-container {
      margin-left: 0;
      flex-flow: row nowrap;
      align-items: stretch;
      margin-top: 16px;

      @media screen and (min-width: 40em) {
        margin-top: 24px;
      }

      li {
        flex: 1;

        &:first-child .tab-trigger {
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
        }

        &:last-child .tab-trigger {
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }

        .tab-trigger {
          font-size: 16px;
          color: #414141;
          border: 1px solid #BDBDBD;
          margin: 0;
          padding: 10px 4px;
          font-family: NNNittiGrotesk-TextRegular;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &.active {
            color: #414141;
            font-weight: bold;
            border-color: #ee7f00;
            font-family: NNNittiGrotesk-Heading;
          }
        }
      }
    }

    .content-container {
      padding: 8px 24px;

      @media screen and (min-width: 40em) {
        padding: 8px 0;
      }

      @media screen and (max-width: 39.99875em) {
        img {
          max-width: 160px;
          margin-bottom: 16px;
        }
      }
    }
  }

  .tab-content {
    background-color: #F7F7F7;
    padding-top: 24px;
    padding-bottom: 24px;

    img {

      @media(max-width: 1023px) {
        max-width: 160px;
      }
    }
  }

  &.description-off {
    padding: 0 !important;

    .no-padding {
      display: none;
    }
  }
}