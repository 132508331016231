.main-nav {

  @media (min-width: 1024px) {

    li[data-path$="/zycie-i-zdrowie"] {

      .nav-item-container .banner-header {
        display: block;
      }
    }

    .nav-item-container {
      min-height: 310px;

      & > .row {
        position: relative;
      }

      .banner-header {
        display: none;
        position: absolute;
        top: 0;
        right: 2rem;
        background: #F2F2F2;
        border-radius: 3px;
        color: #404040;

        &.banner-a {
          padding: 24px;

          .header {
            margin-top: 8px;
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 1.4;
          }

          .sub-header {
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 1.25;
          }

          .link {
            margin: 0;
            padding: 0 !important;
            color: #EA650D;
            font-size: 16px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.banner-b {

          .inner-wrapper {
            display: flex;

            img {
              width: 108px;
            }

            .cta-wrapper {
              padding: 24px;

              .header {
                font-size: 16px;
              }

              .sub-header {
                margin: 16px 0;
                font-size: 26px;
                line-height: 1.3;

                span {
                  color: #EA650D;
                }
              }

              .button {
                padding: 12px 24px;
                background-color: #404040;;

                &:hover {
                  color: #fff;
                  background-color: #2B2B2B;
                }
              }
            }
          }

        }
      }
    }
  }
}