/* Arrows */
@mixin arrow($color:#000, $size:'5px', $direction:'up', $height: $size, $edge:'none') {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;

  @if $direction == 'up' {
    @if $edge == 'right' {
      border-right: $size solid $color;
    } @else {
      border-right: $size solid transparent;
    }
    @if $edge == 'left' {
      border-left: $size solid $color;
    } @else {
      border-left: $size solid transparent;
    }
    border-bottom: $height solid $color;
    border-top: $size solid transparent;
  }

  @if $direction == 'down' {
    @if $edge == 'right' {
      border-right: $size solid $color;
    } @else {
      border-right: $size solid transparent;
    }
    @if $edge == 'left' {
      border-left: $size solid $color;
    } @else {
      border-left: $size solid transparent;
    }
    border-bottom: $size solid transparent;
    border-top: $height solid $color;
  }

  @if $direction == 'right' {
    @if $edge == "up" {
      border-top: $size solid $color;
    } @else {
      border-top: $size solid transparent;
    }

    @if $edge == "down" {
      border-bottom: $size solid $color;
    } @else {
      border-bottom: $size solid transparent;
    }

    border-left: $height solid $color;
    border-right: $size solid transparent;
  }

  @if $direction == 'left' {
    @if $edge == "up" {
      border-top: $size solid $color;
    } @else {
      border-top: $size solid transparent;
    }

    @if $edge == "down" {
      border-bottom: $size solid $color;
    } @else {
      border-bottom: $size solid transparent;
    }
    border-left: $size solid transparent;
    border-right: $height solid $color;
  }
}
