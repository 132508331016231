@import "design-system/common/colors";
@import "common/colors-a11y-buttons";
@import "design-system/common/variables";
@import "design-system/common/mixins";

/* components */
@import "components/step-process-arrowed.scss";
@import "components/cookie-hub.scss";
//@import "components/custom-dropdown-nav.scss";
@import "components/notifications.scss";
@import "components/map/map.scss";
@import "components/map/map-luxmed";
@import "components/form-custom.scss";
@import "components/blog-nav.scss";
@import "components/insurance-offers-slide";
@import "components/main-slider-slide";
@import "components/badges-tags";
@import "components/agents-table";
@import "components/plain-text";

/*!* modules *!*/
@import "design-system/modules/custom-tooltip";

/*!* sections *!*/
@import "sections/sections-superbrands-icons-list.scss";
@import "sections/section-nnlife-form.scss";
@import "sections/section-superbrands-tabs.scss";

/*!* fixes *!*/
@import "fixes/header-dropdown";

/*!* in test *!*/
@import "components/banners-nav";

// /*!* font test *!*/
// @import "tests/font-grotesk";
