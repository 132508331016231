/* CUSTOM TOOLTIP
--------------------------------------------------------------------------
========================================================================*/

.tooltip-wrapper {
  position: relative;
}

/* Tooltip trigger
------------------------------------------------------------------------*/

.custom-tooltip {

  .tooltip-trigger {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
    color: $color-orange-medium;
    font-size: 10px;
    line-height: 1.5;
    text-align: center;
    vertical-align: text-top;
    cursor: pointer;
    transition: all .2s;
    z-index: 2;

    &:hover {
      z-index: 4;
    }

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  }
}

/* Tooltip content
------------------------------------------------------------------------*/

.tooltip-content {
  position: fixed;
  display: block;
  top: 2000px;
  left: 0;

  min-width: 200px;
  line-height: 1.2;
  padding: 8px 28px 8px 8px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  color: $color-gray;
  background: $color-white;
  z-index: 1051;
  font-family: "NNNittiGrotesk-TextRegular";
  border-radius: 3px;
  transform: translate3d(-8px, -10px, 0px) rotate3d(0, 0, 1, 15deg);
  transform-origin: 50% calc(100% + 6em) 0;
  transition: .2s #{$transition-bezier};
  filter: drop-shadow(0px 1px 4px rgba(65, 65, 65, 0.1)) drop-shadow(0px 3px 10px rgba(65, 65, 65, 0.1)) drop-shadow(0px 10px 20px rgba(65, 65, 65, 0.1));
  backface-visibility: hidden;

  @media (max-width: 1023px) {
    width: 200px;
  }

  p,
  a,
  li {
    font-size: 12px;
  }

  p+p {
    margin-top: 8px;
  }

  ul {
    list-style: none;
    margin: 0 0 8px 0;
    position: relative;

    li {
      padding-left: 11px;
      margin-top: 2px;

      &::before {
        content: "\2022";
        color: $color-orange;
        position: absolute;
        left: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 320px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate3d(-8px, -10px, 0px) rotate3d(0, 0, 0, 0deg);
  }

  a {
    position: relative;
    display: inline-block;
    padding-right: 24px;
    margin-top: 16px;
    font-family: $nndagny-text-regular;
    font-size: 12px;
    line-height: 16px;
    color: $color-orange;

    &::after {
      position: relative;
      content: '\00a0>';
    }

    &:hover {
      color: $color-orange;
      text-decoration: underline;
    }

    &+a {
      margin-top: 8px;
    }
  }

  .btn-close {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 8px;
    color: $color-orange;

    .nn-icon-close {
      color: inherit;
    }
  }

  b {
    font-family: $nndagny-text-regular;
    font-weight: 700;
  }

  &.wide {
    @media (max-width: 639px) {
      width: 192px;
    }
  }
}

/* Tooltip content position
------------------------------------------------------------------------*/

.tooltip-content {

  &::after {
    position: absolute;
    content: '';
    display: block;
    @include arrow($color-white, 8px, down);
    bottom: -16px;
    left: 23px;
  }

  &.floated-left {

    transform: translate3d(calc(-100% + 30px), -10px, 0) rotate3d(0, 0, 1, -15deg);
    //left: inherit;
    //right: -7px;

    &::after,
    &::before {
      left: unset;
      right: 10px;
    }

    &.active {
      transform: translate3d(calc(-100% + 30px), -10px, 0) rotate3d(0, 0, 1, 0);
    }
  }

  /// FLOATED CENTER UNUSED
  &.floated-center {

    //left: 50%;
    transform: translate3d(-50%, 0px, 0px) rotate3d(0, 0, 1, 15deg);

    &::after,
    &::before {
      left: 50%;
      transform: translate3d(-50%, 0px, 0px) rotate3d(0, 0, 1, 15deg);
    }


    &.active {
      transform: translate3d(-50%, 0px, 0px) rotate3d(0, 0, 0, 0deg);
    }

  }

  &.floated-up {
    transform: translate3d(-8px, calc(100% + 34px), 0px) rotate3d(0, 0, 1, -15deg);
    transform-origin: 100% -100% 0;
    //bottom: 0;

    &::after,
    &::before {
      top: unset;
      //transform: translate(0%, -100%);
      bottom: 100%;
      @include arrow($color-orange, 8px, up);
    }

    &::after {
      bottom: calc(100% - 2px);
      @include arrow($color-white, 8px, up);
    }

    &.active {
      transform: translate3d(-8px, calc(100% + 34px), 0px) rotate3d(0, 0, 0, 0deg);
    }

    &.floated-left {

      transform: translate3d(calc(-100% + 30px), calc(100% + 34px), 0) rotate3d(0, 0, 1, -15deg);
      //left: inherit;
      //right: -7px;

      &::after,
      &::before {
        left: unset;
        right: 0;
      }

      &.active {
        transform: translate3d(calc(-100% + 30px), calc(100% + 34px), 0) rotate3d(0, 0, 1, 0);
      }
    }


    &.violet {

      &::after,
      &::before {
        bottom: 100%;
        @include arrow($color-orange, 8px, up);
      }
    }
  }

  &.to-left {
    text-align: left;
    padding: 8px 8px 8px 20px;
  }
}

/* Corner tooltip positioning absolute
------------------------------------------------------------------------*/

.corner-tooltip {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 !important;

  .tooltip-trigger {
    border-radius: 6px 0 0 0;
    position: relative;
    z-index: 1;

    &.active {
      transition: none;

      &+.tooltip-content {
        z-index: 4;
      }
    }
  }
}

.fab-tooltip {
  border: none !important;
  background: none !important;
  overflow: visible !important;
}

.fab-tooltip-content {
  top: 0 !important;
  left: 0 !important;
  border: 1px solid #ffebd5;
  text-align: center !important;
  position: relative !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
  padding: 8px !important;
  width: auto !important;
  box-shadow: none !important;
  filter: none !important;

  &::after {
    display: none !important;
  }
}