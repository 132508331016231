.form-ds {
  $input-border: 1px solid $color-grey-light;
  $input-border-radius: 3px;
  $input-text-color: $color-grey-dark;

  .input-tip {
    display: block;
    position: relative;
    margin-top: 4px; // differs depending on input type
    padding-left: 16px;
    color: $color-grey-medium;
    font-size: 12px;
    line-height: 1.33;
    
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 12px;
      height: 12px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23BDBDBD'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.63727 4.66667L6.66659 3.33333C6.66659 3.16667 6.50046 3 6.33434 3H5.66984C5.50372 3 5.3376 3.16667 5.33759 3.33333L5.3376 4.66667C5.3376 4.75293 5.32782 4.91374 5.33759 5L5.50372 6.33333C5.51349 6.4771 5.6503 6.66667 5.79688 6.66667H6.16821C6.31479 6.66667 6.49069 6.4771 6.50046 6.33333L6.63727 5C6.63727 4.91374 6.63727 4.75293 6.63727 4.66667Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66658 8.33333C6.66658 8.70152 6.36908 9 6.00209 9C5.6351 9 5.33759 8.70152 5.33759 8.33333C5.33759 7.96514 5.6351 7.66667 6.00209 7.66667C6.36908 7.66667 6.66658 7.96514 6.66658 8.33333Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .validation-message {
    @extend .input-tip;
    color: $color-error-red;
    
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23D70000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.63727 4.66667L6.66659 3.33333C6.66659 3.16667 6.50046 3 6.33434 3H5.66984C5.50372 3 5.3376 3.16667 5.33759 3.33333L5.3376 4.66667C5.3376 4.75293 5.32782 4.91374 5.33759 5L5.50372 6.33333C5.51349 6.4771 5.6503 6.66667 5.79688 6.66667H6.16821C6.31479 6.66667 6.49069 6.4771 6.50046 6.33333L6.63727 5C6.63727 4.91374 6.63727 4.75293 6.63727 4.66667Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66658 8.33333C6.66658 8.70152 6.36908 9 6.00209 9C5.6351 9 5.33759 8.70152 5.33759 8.33333C5.33759 7.96514 5.6351 7.66667 6.00209 7.66667C6.36908 7.66667 6.66658 7.96514 6.66658 8.33333Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  button[type="submit"].loading-ds {
    position: relative;
    color: transparent;

    &:before {
      content: "";
      position: absolute;
      right: calc(50% - 12px);
      width: 24px;
      height: 24px;
      border: 2px solid $color-white;
      border-top-color: $color-orange-medium;
      border-radius: 50%;
      animation: spin 1s linear infinite;
  }
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }

  }

  @import "./form-elements/floating-label.scss";
  @import "./form-elements/radio-wrapper.scss";
  @import "./form-elements/custom-checkbox-a11y.scss";
}
