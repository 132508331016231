/* A11Y Buttons */
$btn-primary-bg-color: #404040;
$btn-primary-text-color: #fff;
$btn-secondary-bg-color: #fff;
$btn-secondary-border-color: #404040;
$btn-secondary-text-color: #404040;
    
$btn-hover-bg-color: #2B2B2B;
$btn-hover-text-color: #fff;
$btn-active-shadow: 0px 5px 20px 0px rgba(240, 128, 0, 0.15);
