/* COLORS
--------------------------------------------------------------------------
========================================================================*/

$color-gray:              #414141;
$color-gray-light:        #767676;
$color-gray-bg:           #F2F2F2;
$color-gray-border:       #E0E0E0;
$color-orange:            #EA650D;
$color-orange-dark:       #EE7F00;
$color-orange-light:      #FFF7EE;
$color-orange-hover:      #E64415;
$color-green:             #5AA896;

$color-black:             #000;
$color-white:             #FFFFFF;
$color-off-white:         #F7F7F7;
$color-snow-white:        #F2F2F2;
$color-snow-grey:         #ECECEC;
$color-grey-dark:         #414141;
$color-grey-dark-1000:    #404040;
$color-grey-light:        #BDBDBD;
$color-grey-white:        #E0E0E0;
$color-grey-medium:       #767676;
$color-orange-medium:     #EA650D;
$color-orange-dark-new:   #E64415;
$color-orange-00:         #FFF7EE;
$color-blue-500:          #336FCB;
$color-purple-00:         #E1DDEF;
$color-purple-300:        #9B91CC;
$color-purple-500:        #796ABB;
$color-purple-700:        #35219B;
$color-green-500:         #5AA896;
$color-green-700:         #347663;
$color-green-00:          #D2E9E4;

$color-error-red:         #D70000;


$color-btn-primary-bg: #404040;
$color-btn-primary-text: $color-white;
$color-btn-secondary-bg: $color-white;
$color-btn-secondary-border: #404040;
$color-btn-secondary-text: #404040;

$color-btn-hover-bg-color: #2B2B2B;
$color-btn-hover-text-color: $color-white;
$btn-active-shadow: 0px 5px 20px 0px rgba(240, 128, 0, 0.15);

@mixin icon-colors {
  &.theme-blue-500 {
    background-color: $color-blue-500;
  }
  &.theme-purple-00 {
    background-color: $color-purple-00;
  }
  &.theme-purple-300 {
    background-color: $color-purple-300;
  }
  &.theme-purple-500 {
    background-color: $color-purple-500;
  }
  &.theme-green-500 {
    background-color: $color-green-500;
  }
  &.theme-green-700 {
    background-color: $color-green-700;
  }
  &.theme-green-00 {
    background-color: $color-green-00;
  }
  &.theme-orange-dark {
    background-color: $color-orange-dark-new;
  }
  &.theme-orange-dark-light {
    background-color: $color-orange-dark;
  }
  &.theme-orange-medium {
    background-color: $color-orange-medium;
  }
  &.theme-grey-white {
    background-color: $color-grey-white;
  }
  &.theme-grey-medium {
    background-color: $color-grey-medium;
  }
  &.theme-grey-dark-1000 {
    background-color: $color-grey-dark-1000;
  }
  &.theme-white {
    background-color: $color-white;
  }
}

@mixin bg-colors {
  &.bg-white {
    background: $color-white;
  }
  &.bg-snow-white {
    background: $color-snow-white;
  }
  &.bg-grey-white {
    background-color: $color-grey-white;
  }
  &.bg-off-white {
    background-color: $color-off-white;
  }
  &.bg-orange-00 {
    background-color: $color-orange-00;
  }
  &.bg-green-00 {
    background-color: $color-green-00;
  }
}

