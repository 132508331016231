.custom-checkbox {
  position: relative;
  margin-bottom: 16px;

  input[type="checkbox"] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 24px;
    height: 24px;

    &:focus {
      outline: 2px solid #000;
      outline-offset: 2px;
    }
  }

  label {
    padding-left: 40px;
    color: $color-grey-dark;
    font-size: 16px;
    line-height: 1.5;

    &:before {
      width: 24px;
      height: 24px;
      margin: 0;
      border-radius: 3px;
      border: 1px solid $color-grey-light;
    }

    &:after {
      width: 24px;
      margin-top: 6px;
      font-size: 0.8rem;
    }
  }

  input:focus + label {
    &:before {
      border-color: $color-orange-medium !important;
      box-shadow: rgba(240, 128, 0, 0.14) 0px 5px 20px !important;
    }
  }

  input:hover + label:before,
  input:focus + label:before {
    border-color: $color-orange-medium;
    box-shadow: rgba(240, 128, 0, 0.14) 0px 5px 20px !important;
  }

  &.error {
    label {
      color: $color-error-red;
      &:before {
        border-color: $color-error-red;
      }
    }

    .validation-message {
      margin-top: 8px;
    }
  }
}

.checkbox-wrapper {

  .custom-checkbox:last-of-type {
    margin-bottom: 0;
  }

  &.error {

    label {
      &:before {
        border-color: $color-error-red;
      }
    }

    .validation-message {
      margin-top: 8px;
    }
  }
}
